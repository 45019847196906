import React, { useContext } from "react"
import { AccountContext } from "../../context/account"
import { Flex, Text, Heading } from "rebass"
import SetupEnv from "../../components/setup-env"

const Home = () => {
  const { selectedAccount } = useContext(AccountContext)

  return (
    <Flex flexDirection="column" py={5}>
      {selectedAccount?.environments?.length === 0 ? (
        <>
          <Heading fontSize="36px" mb={2}>
            Welcome to Medusa! <br />
          </Heading>
          <Text mb={4}>
            Let's get your first environment up and running. How would you like
            to proceed?
          </Text>
          <SetupEnv />
        </>
      ) : (
        <Flex>Should show summary</Flex>
      )}
    </Flex>
  )
}

export default Home
