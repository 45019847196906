import React, { useState, useEffect } from "react"
import { Flex, Text, Box } from "rebass"
import { useForm } from "react-hook-form"
import { navigate } from "gatsby"

import Input from "../../components/input"
import Button from "../../components/button"

import Medusa from "../../services/api"

const Source = ({ id }) => {
  const { register, handleSubmit } = useForm()

  const onSave = data => {
    console.log(data)
    // Medusa.returnReasons.create(data).then(({ data }) => {
    //   navigate(`/a/settings/return-reasons`)
    // })
  }

  return (
    <Flex
      as="form"
      onSubmit={handleSubmit(onSave)}
      flexDirection="column"
      pt={5}
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      <Flex width={3 / 5} justifyContent="flex-start">
        <Text mb={4} fontWeight="bold" fontSize={20}>
          Select a source
        </Text>
      </Flex>
      <Flex mb={5} width={3 / 5} flexDirection="column">
        <a href="https://github.com/login/oauth/authorize?client_id=Iv1.b3541ba4a8877479&redirect_uri=http://localhost:6002/a/build-sources/github">
          <Button variant={"primary"}>Authenticate with GitHub</Button>
        </a>
        <Flex mt={4} width="75%">
          <Box ml="auto" />
          <Button variant={"cta"} type="submit">
            Next
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Source
