import React, { useState, useEffect, useContext } from "react"
import { Router } from "@reach/router"
import { Flex, Text, Box } from "rebass"
import { useForm } from "react-hook-form"
import { navigate } from "gatsby"

import Input from "../../components/input"
import Button from "../../components/button"

import Source from "./source"
import { AccountContext } from "../../context/account"

const Index = ({}) => {
  const { handleCreateAccount } = useContext(AccountContext)
  const { register, handleSubmit } = useForm()

  const onSave = data => {
    handleCreateAccount(data).then(() => {
      navigate("/a")
    })
  }

  return (
    <Flex
      as="form"
      onSubmit={handleSubmit(onSave)}
      flexDirection="column"
      pt={5}
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      <Flex width={3 / 5} justifyContent="flex-start">
        <Text mb={4} fontWeight="bold" fontSize={20}>
          New Account
        </Text>
      </Flex>
      <Flex mb={5} width={3 / 5} flexDirection="column">
        <Input
          required={true}
          mb={3}
          name="name"
          label="Account Name"
          ref={register}
          width="75%"
        />
        <Input
          required={true}
          mb={3}
          name="billing_email"
          label="Billing Email"
          ref={register}
          width="75%"
        />

        <Flex mt={4} width="75%">
          <Box ml="auto" />
          <Button variant={"cta"} type="submit">
            Next
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

const NewAccount = () => {
  return (
    <Router>
      <Index path="/" />
      <Source path="source" />
    </Router>
  )
}

export default NewAccount
