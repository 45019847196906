import React, { useContext } from "react"
import { Text, Box, Flex } from "rebass"

import { LocalDevContext } from "../../context/local-dev"

import Button from "../button"
import Modal from "../modal"
import CodeSnippet from "../code-snippet"

const LocalDevFailed = () => {
  const { localUrl, failedToFetch, setFailedToFetch } = useContext(
    LocalDevContext
  )

  let networkError = false
  if (failedToFetch && failedToFetch.message === "Network Error") {
    networkError = true
  }

  return (
    <Modal onClick={() => setFailedToFetch(false)}>
      <Modal.Body>
        <Modal.Header>An error occured</Modal.Header>
        {networkError && (
          <Modal.Content flexDirection="column">
            <Text fontSize="12px" mb={2}>
              Please check that your server is running on <tt>{localUrl}</tt>{" "}
              and that you have added
              <br />
              <tt style={{ userSelect: "all" }}>
                https://app.medusa-commerce.com
              </tt>{" "}
              to your CORS settings
            </Text>
            <Text mt={2} fontSize="12px">
              Start your server with:
            </Text>
            <CodeSnippet type="cli">medusa develop</CodeSnippet>
          </Modal.Content>
        )}
        <Modal.Footer sx={{ justifyContent: "flex-end" }}>
          <Button onClick={() => setFailedToFetch(false)} variant="primary">
            Dismiss
          </Button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  )
}

export default LocalDevFailed
