import { Router } from "@reach/router"
import { navigate } from "gatsby"
import React, { useContext } from "react"
import { useHotkeys } from "react-hotkeys-hook"
import Layout from "../components/layout"
import LocalDevFailed from "../components/local-dev-failed"
import PrivateRoute from "../components/private-route"
import SEO from "../components/seo"
import { LocalDevContext } from "../context/local-dev"
import Collections from "../domain/collections"
import Customers from "../domain/customers"
import Discounts from "../domain/discounts"
import GiftCards from "../domain/gift-cards"
import Home from "../domain/home"
import NewAccount from "../domain/new-account"
import NewEnv from "../domain/new-env"
import Oauth from "../domain/oauth"
import Orders from "../domain/orders"
import Returns from "../domain/orders/returns"
import Swaps from "../domain/orders/swaps"
import Products from "../domain/products"
import Settings from "../domain/settings"

const IndexPage = () => {
  useHotkeys("g + o", () => navigate("/a/orders"))
  useHotkeys("g + p", () => navigate("/a/products"))

  const { failedToFetch, isActive } = useContext(LocalDevContext)

  return (
    <>
      <SEO title="Home" />
      <Router basepath="a">
        <PrivateRoute component={Home} layout={Layout} path="/" />
        <PrivateRoute
          component={NewAccount}
          layout={Layout}
          path="new-account/*"
        />
        <PrivateRoute
          component={Oauth}
          layout={Layout}
          path="oauth/:app_name"
        />
        <PrivateRoute component={Products} layout={Layout} path="products/*" />
        <PrivateRoute
          component={Collections}
          layout={Layout}
          path="collections/*"
        />
        <PrivateRoute
          component={GiftCards}
          layout={Layout}
          path="gift-cards/*"
        />
        <PrivateRoute component={Orders} layout={Layout} path="orders/*" />
        <PrivateRoute component={Returns} layout={Layout} path="returns" />
        <PrivateRoute component={Swaps} layout={Layout} path="swaps" />
        <PrivateRoute
          component={Discounts}
          layout={Layout}
          path="discounts/*"
        />
        <PrivateRoute
          component={Customers}
          layout={Layout}
          path="customers/*"
        />
        <PrivateRoute component={Settings} layout={Layout} path="settings/*" />
        <PrivateRoute component={NewEnv} layout={Layout} path="new-env" />
      </Router>
      {failedToFetch && isActive && <LocalDevFailed />}
    </>
  )
}

export default IndexPage
