import React from "react"
import { Router } from "@reach/router"
import { Flex, Heading, Text } from "rebass"
import SetupEnv from "../../components/setup-env"

const NewEnvIndex = () => {
  return (
    <Flex flexDirection={"column"} pb={5} pt={5}>
      <Heading fontSize="36px" mb={2}>
        Setup environment <br />
      </Heading>
      <Text mb={4}>
        Let's get a new environment up and running. How would you like to
        proceed?
      </Text>
      <SetupEnv />
    </Flex>
  )
}

const NewEnv = () => (
  <Router>
    <NewEnvIndex path="/" />
  </Router>
)

export default NewEnv
