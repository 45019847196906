import { navigate } from "gatsby"
import React from "react"
import { useAuth } from "../../context/auth"

const PrivateRoute = ({
  component: Component,
  location,
  layout: Layout = React.Fragment,
  ...rest
}) => {
  const auth = useAuth()

  console.log(auth)

  if (!auth.isLoggedIn) {
    navigate("/login")
    return null
  }

  return (
    <Layout>
      <Component {...rest} />
    </Layout>
  )
}

export default PrivateRoute
